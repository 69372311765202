import React, { Component } from 'react'
import queryString from 'query-string'

import Layout from '../../components/layout'
import OrderFailure from '../../components/Order/Failure'
import CheckoutOverview from '../../components/CheckoutOverview'

export default class OrderCancelPage extends Component {
  constructor(props) {
    super(props)

    const params = queryString.parse(this.props.location.search)
    const transactionid = (params && params.transactionid) ? params.transactionid : null

    this.state = {
      transactionid: transactionid,
    }
  }

  render() {
    const { transactionid } = this.state

    return (
      <Layout>
        <OrderFailure transactionid={transactionid} />
        <CheckoutOverview />
      </Layout>
    )
  }
}
